<template>
  <header class="py-2">
    <div class="container">
      <div class="md:flex md:justify-between md:items-center">
        <img
          src="/images/main-logo.png"
          alt=""
          class="mx-auto md:mx-0 w-72 md:w-60"
        />
        <div class="pt-3">
          <a href="tel:+60391077445" id="call-btn-top">
            <div
              class="w-56 py-2 mx-auto text-white bg-gray-700 rounded-lg  lg:text-xl hover:bg-gray-500"
            >
              Call Us: 03-91077445
            </div>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>