<template>
  <div>
    <WaBtn />
    <!-- top header -->
    <MainHeader />

    <!-- Main Banner -->
    <MainBanner thankyou="lg:justify-center" />

    <!-- What We Do -->
    <div class="py-10">
      <div class="container">
        <div>
          <h1 class="pb-3 text-2xl font-semibold text-gray-800 uppercase">What We Do</h1>
          <div class="w-10 h-1 mx-auto bg-gray-800"></div>
          <p
            class="pt-6 text-sm leading-tight text-gray-600 lg:w-2/3 lg:leading-tight lg:text-base lg:mx-auto"
          >
            We provide a One-stop Design & Build services for our clients,
            without needing the client to engage multiple Interior design
            consultants and managing various contractors, subcontractors,
            specialists and suppliers etc.
          </p>
        </div>
        <div class="pt-8 md:flex md:flex-wrap lg:items-center">
          <div
            v-for="(item, i) in whatwedo"
            :key="i"
            class="pb-6 md:px-1 md:w-1/2 lg:w-1/4"
          >
            <h1
              class="pb-4 text-lg font-bold text-center text-tg-orange"
            >
              {{ item.alt }}
            </h1>
            <div class="relative custom">
              <img :src="item.image" :alt="item.alt" class="rounded-lg" />
                <!-- <div class="overlay">
                  <h1 class="p-5 text-sm text-gray-600 bg-gray-100 text">
                  {{ item.content }}</h1>
                </div> -->
            </div>
          </div>
        </div>
        <!-- button -->
        <a href="tel:+60391077445" id="wa-btn-middle" class="block pt-6">
          <div class="w-48 mx-auto bg-gray-800 rounded-md">
            <h1 class="py-2 text-base font-bold text-center text-white uppercase">
              Talk to Us <br />
              +603-9107 7445
            </h1>
          </div>
        </a>
      </div>
    </div>


    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-white uppercase">
          Why Choose Us
        </h1>
        <div class="w-10 h-1 mx-auto bg-white"></div>
        <div class="flex flex-wrap pt-5 md:justify-center">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="w-1/2 p-1 md:w-1/3 lg:w-1/6"
          >
            <div class="p-4 rounded-lg bg-tg-blue">
              <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
              <h1
                class="pt-4 text-sm font-semibold leading-tight text-center text-white lg:justify-center lg:flex lg:items-center h-14"
              >
                {{ item.alt }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- our works -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-gray-800 uppercase">
          Our Works
        </h1>
        <div class="w-10 h-1 mx-auto bg-gray-800"></div>
        <div class="pt-10">
          <carousel
            :autoplay="true"
            :loop="true"
            :dots="true"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 2, nav: false },
              1000: { items: 3, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in works" :key="i" class="">
              <div class="px-1">
                <img :src="item.image" :alt="item.alt" class="shadow-md" />
                <p class="pt-4 text-xl font-bold leading-tight text-left">{{item.alt}}</p>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- clienteles -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-gray-800 uppercase">
          Our Clienteles
        </h1>
        <div class="w-10 h-1 mx-auto bg-gray-800"></div>

        <!-- client content -->
        <div class="pt-10">
          <carousel
            :autoplay="true"
            :loop="true"
            :dots="true"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 4, nav: false },
              1000: { items: 5, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in client" :key="i" class="">
              <div class="w-2/3 mx-auto md:w-full">
                <img :src="item.image" :alt="item.alt" class="" />
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- Complimentary -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-form.jpg')"
    >
      <div class="container md:py-20">
        <a
          href="#"
          id="btn-enquire-bottom"
          v-scroll-to="{ el: '#element', duration: 1500 }"
        >
          <h1
            class="px-2 py-4 text-xl font-bold text-center uppercase transition duration-300 ease-in-out border md:text-2xl md:w-2/3 md:mx-auto hover:bg-gold hover:text-gray-800 border-gold text-gold"
          >
             Get A Non-Obligation Consultation Now!
          </h1>
        </a>
      </div>
    </div>

    <!-- About us  -->
    <div class="py-10 bg-gray-800">
      <div class="container">
        <div class="text-white">
          <h1 class="pb-3 text-2xl font-semibold uppercase">About Us</h1>
          <div class="w-10 h-1 mx-auto bg-white"></div>
          <p class="pt-6 text-sm">
            The director of <span class="font-bold">Showood Sdn Bhd</span> has gained more than 15 years of
            interior design practice experience before <span class="font-bold">Showood Sdn Bhd</span> has established
            in December 2011, in previous, <span class="font-bold">Showood Sdn Bhd</span> also known as MayaCreative
            which is serve market for almost 3 year and serve as sole
            proprietor, with same person of capacity and experience, it’s has
            been translate into new organization – <span class="font-bold">a full service in interior
            design consultancy and contractor</span> as a pillar by 8 dedicated person
            along with. Graduated Diploma in Architecture and Bachelor in
            Manufacturing Management with Honour has given wider perspective in
            term of holistic overview in construction industry and manufacturing
            industry which have strong related each other, this will be enable
            to see two side of processes in term of design approach and also the
            fabrication of process to ensure to final product will meet the high
            quality standard The practice currently worked with corporate,
            private, public listed, and ,government clients for the projects
            such as commercial, institutional, residential and mixed-use
            developments. <span class="font-bold">Showood Sdn Bhd</span> strives for personal involvement by the
            directors and associates to ensure the practiced produce the best
            possible interior design solutions to suit all circumstances at
            task. <span class="font-bold">Showood Sdn Bhd</span> is driven by the pursuit of quality unconstrained by
            any personal style, a belief that our product of creations shall
            directly influence by quality of our lives and our well being, <span class="font-bold">we
            pursue our design and run the practice relative to the business need
            of our clients</span> and professional endeavours of our consultants and
            building industry
          </p>
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-gray-800 uppercase">
          Locate Us
        </h1>
        <div class="w-10 h-1 mx-auto bg-gray-800"></div>
        <div class="pt-3 lg:pt-8 lg:flex">
          <div class="lg:w-1/2 lg:pl-4">
            <img
              src="/images/main-logo.png"
              alt=""
              class="w-64 mx-auto lg:mx-0 lg:w-80 md:w-72"
            />
            <h1
              class="pt-6 text-base font-semibold leading-tight text-gray-800 lg:text-xl lg:text-left"
            >
              No. 35-1, 1st Floor, Jalan Damai Niaga 1,
              <br class="hidden lg:block" />
              Alam Damai Cheras, 56000 Kuala Lumpur
            </h1>
            <p class="pt-3 text-base font-bold text-gray-800 uppercase lg:text-left">
              <i class="fas fa-phone-alt"></i>
              Office :
              <a href="tel:+60391077445" class="hover:text-gold">03-91077445</a> | 
              <a href="tel:+601966240545" class="hover:text-gold">019-6624054</a>
            </p>
            <p class="text-base font-bold text-gray-800 uppercase lg:text-left">
              <i class="fab fa-whatsapp"></i>
              Whatsapp :
              <a href="https://wa.me/60196624054" class="hover:text-gold"
                >019-6624054</a
              >
            </p>
            <!-- <p class="text-base font-semibold text-gray-800 lg:text-left">
              E-mail :
              <a href="mailto:showood10@gmail.com" class="hover:text-gold"
                >showood10@gmail.com</a
              >
            </p> -->
          </div>
          <div class="pt-6 lg:w-1/2 lg:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0759870417905!2d101.73534021474987!3d3.074378754465135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc355c4395b9e9%3A0x91bb66a6fe481c11!2sShowood%20Sdn.%20Bhd!5e0!3m2!1sen!2smy!4v1632106567645!5m2!1sen!2smy"
              width="100%"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>

    <!-- Copyright -->
    <div class="py-3 bg-gray-800">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import carousel from "vue-owl-carousel";
import WaBtn from "@/components/WaBtn.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    carousel,
    WaBtn,
    MainHeader,
    MainBanner,
  },
  data() {
    return {
      choose_us: [
        { image: "/images/icon-1.png", alt: "Over 15 Years of Experience" },
        { image: "/images/icon-2.png", alt: "Quality Assured" },
        { image: "/images/icon-3.png", alt: "Trusted & Transparent" },
        { image: "/images/icon-4.png", alt: "Best Price" },
        { image: "/images/icon-5.png", alt: "98% Customer Satisfaction" },
      ],
      client: [
        { image: "/images/client-1.jpg", alt: "" },
        { image: "/images/client-2.jpg", alt: "" },
        { image: "/images/client-3.jpg", alt: "" },
        { image: "/images/client-4.jpg", alt: "" },
        { image: "/images/client-5.jpg", alt: "" },
        { image: "/images/client-6.jpg", alt: "" },
        { image: "/images/client-7.jpg", alt: "" },
        { image: "/images/client-8.jpg", alt: "" },
        { image: "/images/client-9.jpg", alt: "" },
      ],
      works: [
        { image: "/images/work-1.jpg", alt: "Malaysia Debt Venture" },
        { image: "/images/work-2.jpg", alt: "Rasa-Rasa Restaurant, Hotel Seri Malaysia" },
        { image: "/images/work-3.jpg", alt: "UEM Sunrise, Symphony Hill" },
        { image: "/images/work-4.jpg", alt: "Continental HQ" },
        { image: "/images/work-5.jpg", alt: "Menara MOF Office" },
      ],
      whatwedo: [
        {
          image: "/images/product-1.jpg",
          alt: "Office Interior Design",
          content:
            "Residential interior design is the art of creating aesthetic and functional living spaces. Designers work with architects to ensure that physical features allow for the appropriate division of private versus public space and can also aesthetically divide or unify rooms through colours, textures, and patterns",
        },
        {
          image: "/images/product-2.jpg",
          alt: "Commercial Interior Design",
          content:
            "Commercial interior design includes a broad spectrum of interior commercial spaces and environments, including offices, retail stores, restaurants, and other spaces where business is conducted. Commercial interior designers are professionals who create and direct the construction of these commercial spaces.",
        },
        {
          image: "/images/product-3.jpg",
          alt: "Hotel Interior Design",
          content:
            "Refurbishment is the process of improvement by cleaning, decorating and re-equipping. In general, refurbishment can encompass such works as 'cosmetic' renovations (such as painting and decorating), upgrading, major repair work,alterations, conversions, extensions and modernisations",
        },
        {
          image: "/images/product-4.jpg",
          alt: "Residential",
          content:
            "We also specialize in supplying unique designer, quality, handpicked loose furniture and decor from abroad at a very competitive price. We have a designated team of Designers to do sourcing, and Logistics team to do quality control in China and soon Thailand, Indonesia, and abroad.",
        },
      ],
    };
  },
};
</script>

<style>
.overlay {
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.custom:hover .overlay {
  opacity: 1;
}

.text {
  /* font-size: 20px; */
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>

