<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="px-3 py-20 md:px-0 md:py-0 lg:container">
      <div class="md:flex md:items-center" v-bind:class="thankyou">
        <div class="pb-8 text-gray-800 md:text-left md:px-2 md:w-1/2 lg:w-1/2">
          <h1 class="text-4xl font-bold leading-tight lg:pb-2 lg:text-5xl">
            Looking for Office Commercial <br class="hidden md:block" />
          </h1>
          <h1 class="w-5/6 p-2 mx-auto text-4xl font-bold text-center text-white bg-gray-800 lg:text-5xl lg:w-96 md:mx-0">
            Interior Design?
          </h1>
          <p
            class="pt-5 text-2xl font-medium leading-tight text-gray-800 lg:text-2xl"
          >
            Call us for consultation
          </p>
        </div>
        <div
          id="element"
          v-if="!hideEnquiry"
          class="bg-gray-900 bg-opacity-75 md:w-1/2 lg:w-1/3"
        >
          <div class="bg-tg-darkblue">
            <h1
              class="p-4 text-lg font-bold leading-tight text-center text-white uppercase"
            >
              Get A Non-Obligation Consultation
            </h1>
          </div>
          <!-- enquiry -->
          <!-- feedback.activamedia.com.sg script begins here --><iframe
            allowTransparency="true"
            style="min-height: 600px; height: inherit; overflow: auto"
            width="100%"
            id="contactform123"
            name="contactform123"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5995270.html"
            ><p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p></iframe
          ><!-- feedback.activamedia.com.sg script ends here -->
          <!-- end enquiry -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
    thankyou: {
      default: true,
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  #element iframe {
    min-height: 600px;
  }
}
</style>
